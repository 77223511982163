import React from "react";
import './productos.css';

class Productos extends React.Component {

   


    mostrarCategorias = (categorias) => {

        let categoriasListado = []
        
        categorias.map(cat => { categoriasListado.push(cat.name)})

        let categoriasString = categoriasListado.join(' - ')

        return categoriasString
    }

    copyToClipboard = (text) => {

        if (this.props.numero === null) {alert("¡ERROR! No te olvidés de poner tu número de vendedor")}

        else {

        const textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        textarea.value = text;
        textarea.select();
        textarea.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(textarea);
        
        }
      };
    

    
    
    

   

  


    render () {
        return (<div className="contenedor-productos">

        <div className="producto" id="titulo-div">
           <h2>{this.props.categoriaName}</h2>
           <div className="botones-div" id="margin-top-0"><button onClick={() => this.copyToClipboard('https://dxelectronica.com.ar/categoria-producto/' + this.props.categoriaSlug + '/?ref=' + this.props.numero)}>Copiar Link de Ventas</button><a href={'https://dxelectronica.com.ar/categoria-producto/' + this.props.categoriaSlug} target='blank'>Ver en el sitio web <i className="fa fa-arrow-right" aria-hidden="true"></i></a></div>
        </div>

            {

                       
            this.props.palabra.length === 0 ? (
                this.props.listado.map(prod => {

                    
                    let perm = prod.permalink + '?ref=' + this.props.numero

                    return (<div key={prod.id}>

                      
                        
                        <div className="producto">
                            <img src={prod.images[0].src}/>
    
                            <div className="product-info">
                                <p className="categorias-prod">{this.mostrarCategorias(prod.categories)}</p>
                                <h1>{prod.name}</h1>
                                {prod.type == 'grouped' ? (<p className="precio-prod">Desde $ {prod.price}</p>) : (<p className="precio-prod">$ {prod.price}</p>)}
                                <div className="botones-div"><button onClick={() => this.copyToClipboard(perm)}>Copiar Link de Ventas</button><a href={prod.permalink} target='blank'>Ver en el sitio web <i className="fa fa-arrow-right" aria-hidden="true"></i></a></div>
                            </div>

                        </div>
                        
                    </div>)
                })

            ) : (

                this.props.listado.map(prod => {

                    let per = prod.permalink + '?ref=' + this.props.numero

                    return (<div key={prod.id}>

                       {prod.name.toLowerCase().includes(this.props.palabra) ? (
                        <div className="producto">
                            <img src={prod.images[0].src}/>
    
                            <div className="product-info">
                                <p className="categorias-prod">{this.mostrarCategorias(prod.categories)}</p>
                                <h1>{prod.name}</h1>
                                {prod.type == 'grouped' ? (<p className="precio-prod">Desde $ {prod.price}</p>) : (<p className="precio-prod">$ {prod.price}</p>)}
                                <div className="botones-div"><button onClick={() => this.copyToClipboard(per)}>Copiar Link de Ventas</button><a href={prod.permalink} target='blank'>Ver en el sitio web <i className="fa fa-arrow-right" aria-hidden="true"></i></a></div>
                            </div>
                        </div>

                       ) : (null)}
                        
                        
                        
                    </div>)
                })
            )

            
            
            }
            

        </div>)
    }
}

export default Productos
import './App.css';
import Header from './components/header/header'
import './reset.css'
import Principal from './components/principal/principal'

function App() {
  return (
    <div className="App">
     <Header />
     <Principal />
    </div>
  );
}

export default App;

import React from "react";
import './principal.css'
import Buscando from "../buscando/buscando";
import Productos from "../productos/productos";

class Principal extends React.Component {

    state = {
      
       productosFiltrados: [],
       productos: [],
       palabraClave: '',
       referido: null,
       categorias: [],
       categoriaElegida: 33,
       test: '',
       categoriaElegidaName: 'Auriculares',
       categoríaElegidaSlug: 'auriculares'
    }

    cambiarPalabraClave = (e) => {
        e.preventDefault()
        let term = e.target.value.toLowerCase()
        this.setState({palabraClave: term})
    }

    cambiarReferido = (e) => {
        e.preventDefault()
        this.setState({referido: e.target.value})
    }

    cambiarCategoria = (e) => {
        e.preventDefault()
        this.setState({categoriaElegida: e.target.value}, () => {

            if (this.state.categoriaElegida === 'Todas') {this.setState({productosFiltrados: this.state.productos})}

           else {
            let urli = 'https://dxelectronica.com.ar/wp-json/wc/v3/products?consumer_key=ck_15772bcb419ec78bc926cae12fa70ead598be87a&consumer_secret=cs_cf199b70c08f7af6255b2ef4554a2fd54d0d92a2&page=1&per_page=100&stock_status=instock&status=publish&category='
            let cateleg = this.state.categoriaElegida.toString()
            let urlFinal = urli + cateleg

                     
            // buscar name en array de categorias en cada cambio en el select
           
            let arrayCategorias = this.state.categorias
            console.log(arrayCategorias)
            let indexCategoriaElegida = arrayCategorias.findIndex(x => x.id === Number(e.target.value))
            let categoriaName = arrayCategorias[indexCategoriaElegida].name
            let categoriaSlug = arrayCategorias[indexCategoriaElegida].slug

            this.setState({categoriaElegidaName: categoriaName})
            this.setState({categoríaElegidaSlug: categoriaSlug})

            // sigo con el desarrollo original (consulta de productos para esa categoría)

            fetch(urlFinal).then(response => response.json()).then(
                data => {
                let productosFilt = data.map(({name, permalink, price, images, categories, id, type}) => ({name, permalink, price, images, categories, id, type}));
                this.setState({productosFiltrados: productosFilt})
                })
               
           }

        })
    }

    componentDidMount = () => {

        let finalCat = []

        fetch('https://dxelectronica.com.ar/wp-json/wc/v3/products/categories?consumer_key=ck_15772bcb419ec78bc926cae12fa70ead598be87a&consumer_secret=cs_cf199b70c08f7af6255b2ef4554a2fd54d0d92a2&per_page=100')
        .then(response => response.json())
        .then(data => {

            let soloNames = data.map(({name, parent, id, slug}) => ({name, parent, id, slug}));
            soloNames.map(cat => {
                if (cat.parent !== 0)
                finalCat.push(cat)
            })
            this.setState({categorias: finalCat})

        })

       
        let productosCrudo = []
      

        fetch('https://dxelectronica.com.ar/wp-json/wc/v3/products?consumer_key=ck_15772bcb419ec78bc926cae12fa70ead598be87a&consumer_secret=cs_cf199b70c08f7af6255b2ef4554a2fd54d0d92a2&page=1&per_page=100&stock_status=instock&status=publish&category=33')
            .then(response => response.json())
            .then(data => {this.productosCrudo = data})
            .then(() => {
                let productosfinal = this.productosCrudo.map(({name, permalink, price, images, categories, id, type}) => ({name, permalink, price, images, categories, id, type}));
                this.setState({productos: productosfinal, productosFiltrados: productosfinal})
            })
        

    }

   
render () {
    return <div>
    
    {this.state.productos.length === 0 ? (<Buscando />) : (
        <div>

        <div className="filtros">
            
            <div className="palabra-div">
                <p>Categoría:</p>
                <select onChange={this.cambiarCategoria}>
                    <option value='Todas' hidden>Auriculares</option>
                    {this.state.categorias.map(cat => {
                        return (<option value={cat.id} key={cat.name}>{cat.name}</option>)
                    })}
                </select>
            </div>

            <div className="palabra-div">
                <p>Palabra clave:</p>
                <input onChange={this.cambiarPalabraClave} />
            </div>

            <div className="palabra-div">
                <p>N° Vendedor</p>
                <input type='number' id='corto' onChange={this.cambiarReferido}/>
            </div>
            
        </div>


        <Productos listado={this.state.productosFiltrados}  palabra={this.state.palabraClave} numero={this.state.referido} categoria={this.state.categoriaElegida} categoriaName={this.state.categoriaElegidaName} categoriaSlug={this.state.categoríaElegidaSlug}/>
        </div>
    )}

        

    

    </div>
}

}

export default Principal



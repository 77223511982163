import React from "react";

class Buscando extends React.Component {


    render () {
        return (<div className="carg">
            
        </div>)
    }
}

export default Buscando
import React from "react";
import './header.css'

class Header extends React.Component {


render () {
    return <div>

        <div className='header'>

        <img src='https://dxelectronica.com.ar/wp-content/uploads/2021/06/logo-dxelectronica-plateado-sin-fondo-01-ok.png' alt='logo dx' />
       
        </div>


    </div>
}


}


export default Header